<template>
  <div>
    <v-chip :color="statuses.color" small outlined class="mr-1 mb-1">{{
      statuses.text
    }}</v-chip>
    <option-row title="ID заявки:">{{ request.id }}</option-row>
    <option-row title="Заявка от кружка:"
      ><router-link
        class="link"
        :to="{ name: 'organization', params: { orgId: request.source.id } }"
        >{{ groupName }}</router-link
      ></option-row
    >
    <option-row v-if="request?.author" title="Автор заявки:"
      ><a
        class="link"
        href="#"
        @click.stop.prevent="openDialog(request.author)"
        >{{ author || request.author }}</a
      ></option-row
    >
    <option-row v-if="createDate" title="Дата создания:">{{
      createDate
    }}</option-row>
    <option-row v-if="updateDate" title="Дата обновления:">{{
      updateDate
    }}</option-row>
    <option-row v-if="contactFullName" title="ФИО контактного лица:">{{
      contactFullName
    }}</option-row>
    <option-row
      v-if="request.source?.contact_phone"
      title="Телефон контактного лица:"
      >{{ request.source.contact_phone }}</option-row
    >
    <option-row
      v-if="request.source?.contact_email"
      title="Почта контактного лица:"
      ><a :href="`mailto:${request.source.contact_email}`">{{
        request.source.contact_email
      }}</a></option-row
    >
  </div>
</template>

<script>
import { talentClient } from "@/api";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import dayjs from "@/plugins/dayjs";
export default {
  name: "RequestsCard",
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      author: null,
    };
  },
  computed: {
    statuses() {
      const { status } = this.request;
      switch (status) {
        case "r":
          return {
            text: "Отклонена",
            color: "error",
          };
        case "a":
          return {
            text: "Подтверждена",
            color: "success",
          };
        default:
          return {
            text: "На модерации",
            color: "info",
          };
      }
    },
    contactFullName() {
      if (!this.request.source) return;
      const { contact_last_name, contact_first_name, contact_middle_name } =
        this.request.source;
      return [contact_last_name, contact_first_name, contact_middle_name]
        .filter(Boolean)
        .join(" ");
    },
    createDate() {
      if (!this.request?.created_at) return;
      return dayjs(this.request.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    updateDate() {
      if (!this.request?.updated_at) return;
      return dayjs(this.request.updated_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    groupName() {
      return (
        this.request.source?.full_name ||
        this.request.source?.short_name ||
        this.request.source?.id
      );
    },
  },
  created() {
    this.getAuthor();
  },
  methods: {
    async getAuthor() {
      if (!this.request.author) return;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${this.request.author}/`,
        });
        if (data.id) {
          this.author = this.getFullName(data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    getFullName(user) {
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
    openDialog(userId) {
      if (userId) {
        this.$emit("openDialog", userId);
      }
    },
  },
};
</script>
