<template>
  <div>
    <requests-section :requests="requests" @setRequestsPage="getRequests" />
  </div>
</template>
<script>
import { talentClient } from "@/api";
import { initialListingModel } from "@/utils";
import RequestsSection from "@/components/organizations/RequestsSection.vue";
export default {
  name: "OrganizationRequestsPage",
  components: { RequestsSection },
  data() {
    return {
      requests: initialListingModel(5),
    };
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (!val) return;
        this.getRequests(this.requests.page);
      },
    },
  },
  activated() {
    if (this.organization) {
      this.getRequests(this.requests.page);
    }
  },

  methods: {
    async getRequests(page = 1) {
      const owner = this.organization?.admin_users?.find((u) => u.is_owner);
      if (this.requests.pending || !owner?.user_id) return;
      this.requests.error = "";
      this.requests.pending = true;
      this.requests.page = page;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${owner.user_id}/organizations/${this.organization.id}/invite/requests/`,
          params: {
            limit: this.requests.limit,
            offset: (this.requests.page - 1) * this.requests.limit,
            target: this.organization.id,
          },
        });
        this.requests.total = data.count;
        this.requests.list = data.results;
        this.requests.pagesCount = Math.ceil(data.count / this.requests.limit);
      } catch (error) {
        this.requests.error = error.message;
      }
      this.requests.pending = false;
    },
  },
};
</script>
