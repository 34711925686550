<template>
  <info-section
    class="mb-4"
    :total="requests.total"
    :pending="requests.pending"
    icon="mdi-office-building-plus"
    title="Заявки на присоединение к организации"
    :pages="requests.pagesCount"
    :current-page="requests.page"
    @setPage="setPage"
  >
    <request-card
      v-for="(req, idx) in requests.list"
      :key="req.id"
      :request="req"
      class="item"
      :class="{ last: idx === requests.list.length - 1 }"
      @openDialog="openDialog"
    />
    <div v-if="!requests.list.length && !requests.pending && isOrganization">
      Нет данных
    </div>
    <v-dialog v-model="contactDialog" max-width="600px">
      <contact-dialog
        :is-active="contactDialog"
        :talent-id="selectedUserId"
        @close="contactDialog = false"
      />
    </v-dialog>
  </info-section>
</template>
<script>
import InfoSection from "@/components/InfoSection.vue";
import RequestCard from "@/components/organizations/RequestCard.vue";
import ContactDialog from "@/components/dialogs/ContactDialog.vue";
export default {
  name: "RequestSection",
  components: { InfoSection, RequestCard, ContactDialog },
  props: {
    requests: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contactDialog: false,
      selectedUserId: null,
    };
  },
  computed: {
    isOrganization() {
      return !!this.$store.state.organizations.organization;
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setRequestsPage", page);
    },
    openDialog(userId) {
      this.selectedUserId = userId;
      this.contactDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &.last {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
